import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router,) { }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    let ExpDate = (Math.floor((new Date).getTime() / 1000)) >= expiry;
    console.log(ExpDate);
    return ExpDate


  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,) {
    let isAuth = this.authService.isAuthenticated();
    let token = this.authService.getToken()

    if (token === null) {
      this.router.navigate(['/login']);
    }
    else if (this.tokenExpired(token)) {
      localStorage.clear();
      this.router.navigate(['/login']);
    }
    else {
      console.log('User is Authorzed with token in Auth Guard')
      return true;
    }

    // if (!isAuth) {
    //   this.router.navigate(['/pages/login']);
    // }
    // else {
    //   return true;
    // }
  }
}
