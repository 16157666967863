import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  url: any;
  constructor(private authservice: AuthService, private router: Router, private route: ActivatedRoute) {
    this.route.url.subscribe(activeUrl => {
      // this.url = window.location.pathname;
      // console.log(activeUrl)
      // console.log(this.url)
    });
  }


  baseUrl = environment.API_Url;
  callingUrl: any;
  isAuthUrl: any;
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.callingUrl = request.url;
    console.log("interceptor authorization Url : " + this.callingUrl)
    let token = this.authservice.getToken();
    this.isAuthUrl = this.callingUrl.substring(0, this.callingUrl.lastIndexOf('/'));

    if (token == null && this.isAuthUrl == '/Cust') {
      let navUrl = "/login";
      console.log("Authorization Not Found Going to Login " + navUrl)
      this.router.navigate(['/login']);
    }



    let modReq;
    if (this.isAuthUrl === '/Cust') {

      let token = this.authservice.getToken();
      const newtoken = token.slice(1, -1);
      modReq = request.clone({
        setHeaders: {
          'Authorization': newtoken,
          'Accept': '*/*',
        },
        url: `${this.baseUrl}${request.url}`

      })

    } else if (this.isAuthUrl === './assets/i18n' || this.isAuthUrl === './assets/i18n/en.json') {


      modReq = request.clone();

    } else {

      // console.log("requesting without authorization : " + this.isAuthUrl)

      modReq = request.clone({
        url: `${this.baseUrl}${request.url}`
      })
    }



    // console.log("request url "+request.url)


    //   request = request.clone({
    //     setHeaders: {
    //         'Authorization': this.token,
    //     }
    // })
    // console.log("interceptor request " + JSON.stringify(request))
    return next.handle(modReq);
  }
}
