import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http'
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { User } from 'app/pages/content-pages/login/user';
import { json } from 'ngx-custom-validators/src/app/json/validator';
import { LoginObj } from 'app/pages/content-pages/login/login';
import { BehaviorSubject } from 'rxjs';



@Injectable()
export class AuthService {

  constructor(public router: Router, private http: HttpClient) {
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }


  isloginStatus: String;
  resut: string = '';
  signinUser(data: LoginObj): Observable<any> {
    console.log("service form " + JSON.stringify(data));

    this.isloginStatus = 'Testing';
    return this.http.post<any>('/auth/authenticate', data)//.pipe(
  }



  logout() {
    console.log("in logout")
    localStorage.removeItem('token');
    localStorage.clear();
    //this._firebaseAuth.signOut();
    this.router.navigate(['login']);

  }
  user: User = {
    profilePic: ' ',
    custID: null,
    cstatus: '',
    webUserName: '',
    webPassword: '',
    GetTime: "",
    getTime: "",
    role: [],
    userFirstName: "",
    userLastName: "",
    userName: "",
    userPassword: ""
  };
  getUser(): User {
    this.user = JSON.parse(localStorage.getItem('user'));
    return this.user
  }
  isActive(): boolean {
    let retrievedObject = this.getUser();

    if (retrievedObject.cstatus == 'InActive') {
      localStorage.clear();

      return true;
    } else {

      return false;
    }
  }
  getToken() {
    const token = localStorage.getItem('token');
    return token
  }

  isAuthenticated() {
    return true;
  }
}
